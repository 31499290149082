import {
    AccessOtherTeams,
    AddEnrollmentVerificationFile,
    ArchiveTeam,
    ArchiveTeamNote,
    ArchiveTeamRhFile,
    ArchiveUserNote,
    ArchiveUserRhFile,
    CalculatePathwayBlueprint,
    CanManageOwnTeam,
    CancelAdpSubscriptionService,
    CensusUpload,
    ConnectToFinch,
    CreateAdpSubscriptionService,
    CreateEnrollmentVerification,
    CreateMarketingRhFile,
    CreatePathway,
    CreatePathwayBlueprints,
    CreateProposal,
    CreateReport,
    CreateRole,
    CreateSelectedPlans,
    CreateTeam,
    CreateTeamBenefit,
    CreateTeamBenefitRhFile,
    CreateTeamNote,
    CreateTeamRhFile,
    CreateUser,
    CreateUserNote,
    CreateUserRhFile,
    DeactivateRhUser,
    DeleteEnrollmentVerification,
    DeleteMarketingRhFile,
    DeletePathway,
    DeletePathwayBlueprints,
    DeletePayrollReports,
    DeleteReport,
    DeleteRole,
    DeleteSelectedPlans,
    DeleteTeam,
    DeleteTeamBenefit,
    DeleteTeamBenefitRhFile,
    DeleteTeamNote,
    DeleteTeamRhFile,
    DeleteUser,
    DeleteUserNote,
    DeleteUserRhFile,
    DeleteUsersForOwnTeam,
    DisconnectFinch,
    DownloadEnrollmentCensus,
    DownloadReimbursementReportRhFile,
    DownloadTeamBenefitRhFile,
    DownloadTeamRhFile,
    DownloadUserRhFile,
    EditEnrollmentVerification,
    EditLockedSubmittedExpense,
    EditMedicaidDetails,
    EditPathway,
    EditPathwayBlueprints,
    EditPayrollIntegrationConfiguration,
    EditPayrollReports,
    EditReport,
    EditRole,
    EditSelectedPlans,
    EditSocialSecurityNumber,
    EditSubmittedExpense,
    EditSurveyTypeToSend,
    EditTaxData,
    EditTeam,
    EditTeamAdmins,
    EditTeamAdvisor,
    EditTeamBenefit,
    EditTeamBenefitRhFile,
    EditTeamNote,
    EditTeamRepresentative,
    EditTeamRhFile,
    EditTeamUserAccess,
    EditUser,
    EditUserBudget,
    EditUserMajorMedicalCoverageLevel,
    EditUserNote,
    EditUserRhFile,
    EditUserRoles,
    EditUserStatus,
    EditUserVerifiedInfo,
    GenerateHrsIntegrationKey,
    ImpersonateUsers,
    InviteUsers,
    LaunchTeam,
    RecalculateBenefitCosts,
    RemoveEnrollmentVerificationFile,
    ResendRenewalInvite,
    ResetAccountLockout,
    RunScheduledJob,
    SendEnrollmentVerificationReminder,
    SendSurveys,
    SetPayrollSystemDeduction,
    SetUserStatusEnrolled,
    SetUserStatusInactive,
    SetUserStatusOffBoarding,
    SetUserStatusWaived,
    ShareReports,
    ShareTeamBenefitRhFile,
    ShareTeamRhFiles,
    ShareUserRhFiles,
    SubmitExpense,
    ViewEnrollmentVerification,
    ViewHrsAssociateId,
    ViewHrsIntegrationKey,
    ViewInvoiceSummary,
    ViewMedicaidDetails,
    ViewMviEmployeeAnnualIncome,
    ViewPathwayBlueprints,
    ViewPayrollReports,
    ViewPayrollSystemId,
    ViewQualitativeAnswers,
    ViewReports,
    ViewRoles,
    ViewSelectedPlans,
    ViewSocialSecurityNumber,
    ViewSubmittedExpenses,
    ViewSurveyTypeToSend,
    ViewTaxData,
    ViewTeamBenefit,
    ViewTeamNotes,
    ViewTeamRhFiles,
    ViewTeamSharedPayrollReports,
    ViewTeamSharedReimbursementReports,
    ViewTeamUserAccess,
    ViewUserBudget,
    ViewUserFlows,
    ViewUserNotes,
    ViewUserRhFiles,
    ViewUserRoles,
    ViewUserVerifiedInfo,
    ViewUserVerifiedInfoForOwnTeam,
    ViewUserWageIncrease,
    ViewUsers,
} from 'api/generated/permissions';

export const PermissionSections: Record<
    string,
    { permissions: string[]; stringsToRemove: string[] }
> = {
    'Enrollment Census': {
        permissions: [DownloadEnrollmentCensus],
        stringsToRemove: ['Enrollment Census'],
    },
    'Enrollment Verification': {
        permissions: [
            CreateEnrollmentVerification,
            DeleteEnrollmentVerification,
            EditEnrollmentVerification,
            ViewEnrollmentVerification,
            AddEnrollmentVerificationFile,
            RemoveEnrollmentVerificationFile,
            SendEnrollmentVerificationReminder,
        ],
        stringsToRemove: ['Enrollment Verification'],
    },
    Integrations: {
        permissions: [
            ConnectToFinch,
            DisconnectFinch,
            ViewHrsAssociateId,
            SetPayrollSystemDeduction,
            EditPayrollIntegrationConfiguration,
        ],
        stringsToRemove: ['Integrations'],
    },
    'Marketing RH Files': {
        permissions: [CreateMarketingRhFile, DeleteMarketingRhFile],
        stringsToRemove: ['Marketing Rh File'],
    },
    Pathways: {
        permissions: [
            ViewPathwayBlueprints,
            CreatePathwayBlueprints,
            DeletePathwayBlueprints,
            EditPathwayBlueprints,
            CalculatePathwayBlueprint,
            CreatePathway,
            EditPathway,
            DeletePathway,
        ],
        stringsToRemove: ['Pathways', 'Pathway'],
    },
    'Payroll Reports': {
        permissions: [
            EditPayrollReports,
            ViewInvoiceSummary,
            ViewPayrollReports,
            ViewTeamSharedPayrollReports,
            DeletePayrollReports,
        ],
        stringsToRemove: ['Payroll Reports'],
    },
    Proposals: {
        permissions: [CreateProposal],
        stringsToRemove: ['Proposal'],
    },
    Reports: {
        permissions: [CreateReport, DeleteReport, EditReport, ShareReports, ViewReports],
        stringsToRemove: ['Reports', 'Report'],
    },
    Roles: {
        permissions: [CreateRole, DeleteRole, EditRole, ViewRoles],
        stringsToRemove: ['Roles', 'Role'],
    },
    'Selected Plans': {
        permissions: [
            CreateSelectedPlans,
            DeleteSelectedPlans,
            EditSelectedPlans,
            ViewSelectedPlans,
        ],
        stringsToRemove: ['Selected Plans'],
    },
    'Service User': {
        permissions: [CancelAdpSubscriptionService, CreateAdpSubscriptionService, RunScheduledJob],
        stringsToRemove: [],
    },
    'Submitted Expenses': {
        permissions: [
            EditSubmittedExpense,
            EditLockedSubmittedExpense,
            ViewSubmittedExpenses,
            SubmitExpense,
            ViewTeamSharedReimbursementReports,
            DownloadReimbursementReportRhFile,
        ],
        stringsToRemove: ['Submitted Expenses', 'Rh File'],
    },
    'Tax Data': {
        permissions: [EditTaxData, ViewTaxData],
        stringsToRemove: ['Tax Data'],
    },
    Teams: {
        permissions: [
            AccessOtherTeams,
            ArchiveTeam,
            CanManageOwnTeam,
            CreateTeam,
            DeleteTeam,
            EditTeam,
            EditTeamAdvisor,
            EditTeamRepresentative,
            GenerateHrsIntegrationKey,
            LaunchTeam,
            ViewHrsIntegrationKey,
        ],
        stringsToRemove: [],
    },
    // eslint-disable-next-line sort-keys-shorthand/sort-keys-shorthand
    'Team Benefits': {
        permissions: [
            CreateTeamBenefit,
            CreateTeamBenefitRhFile,
            DeleteTeamBenefit,
            DeleteTeamBenefitRhFile,
            DownloadTeamBenefitRhFile,
            EditTeamBenefitRhFile,
            EditTeamBenefit,
            RecalculateBenefitCosts,
            ShareTeamBenefitRhFile,
            ViewTeamBenefit,
        ],
        stringsToRemove: ['Team Benefit', 'Rh'],
    },
    'Team Notes': {
        permissions: [ArchiveTeamNote, CreateTeamNote, DeleteTeamNote, EditTeamNote, ViewTeamNotes],
        stringsToRemove: ['Team Notes', 'Team Note'],
    },
    'Team RH Files': {
        permissions: [
            ArchiveTeamRhFile,
            CreateTeamRhFile,
            DeleteTeamRhFile,
            DownloadTeamRhFile,
            EditTeamRhFile,
            ShareTeamRhFiles,
            ViewTeamRhFiles,
        ],
        stringsToRemove: ['Team Rh Files', 'Team Rh File'],
    },
    Users: {
        permissions: [
            CensusUpload,
            CreateUser,
            DeactivateRhUser,
            DeleteUser,
            DeleteUsersForOwnTeam,
            EditUser,
            EditUserMajorMedicalCoverageLevel,
            EditUserStatus,
            ImpersonateUsers,
            ResetAccountLockout,
            SetUserStatusEnrolled,
            SetUserStatusInactive,
            SetUserStatusOffBoarding,
            SetUserStatusWaived,
            ViewPayrollSystemId,
            ViewUsers,
        ],
        stringsToRemove: ['Users', 'User'],
    },
    // eslint-disable-next-line sort-keys-shorthand/sort-keys-shorthand
    'User Access': {
        permissions: [EditTeamUserAccess, ViewTeamUserAccess],
        stringsToRemove: ['Team User Access'],
    },
    'User Budgets/Wage Increases': {
        permissions: [EditUserBudget, ViewUserBudget, ViewUserWageIncrease],
        stringsToRemove: ['User '],
    },
    'User Flows': { permissions: [ViewUserFlows], stringsToRemove: [] as string[] },
    'User Invitation': {
        permissions: [InviteUsers, ResendRenewalInvite, SendSurveys],
        stringsToRemove: [] as string[],
    },
    'User Medicaid Details': {
        permissions: [EditMedicaidDetails, ViewMedicaidDetails],
        stringsToRemove: ['Medicaid Details'],
    },
    'User Notes': {
        permissions: [ArchiveUserNote, CreateUserNote, DeleteUserNote, EditUserNote, ViewUserNotes],
        stringsToRemove: ['User Notes', 'User Note'],
    },
    'User RH Files': {
        permissions: [
            ArchiveUserRhFile,
            CreateUserRhFile,
            DeleteUserRhFile,
            DownloadUserRhFile,
            EditUserRhFile,
            ShareUserRhFiles,
            ViewUserRhFiles,
        ],
        stringsToRemove: ['User Rh Files', 'User Rh File'],
    },
    'User Roles': {
        permissions: [EditTeamAdmins, EditUserRoles, ViewUserRoles],
        stringsToRemove: ['User Roles'],
    },
    'Verified Info': {
        permissions: [
            EditSocialSecurityNumber,
            EditUserVerifiedInfo,
            EditSurveyTypeToSend,
            ViewMviEmployeeAnnualIncome,
            ViewSocialSecurityNumber,
            ViewQualitativeAnswers,
            ViewUserVerifiedInfo,
            ViewUserVerifiedInfoForOwnTeam,
            ViewSurveyTypeToSend,
        ],
        stringsToRemove: [' User Verified Info'],
    },
};
