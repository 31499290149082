import { HouseholdEligibilityStatus, OtherCoverageEligibilities } from 'api/generated/enums';
import { IMemberEligibility } from 'api/generated/models';
import { flagsToValuesArray } from 'utilities';

export const isEligible = (
    status: HouseholdEligibilityStatus,
    eligibility: HouseholdEligibilityStatus
) => {
    const test = status & eligibility;
    return test === eligibility;
};

export const getEligibilityName = (status: HouseholdEligibilityStatus) => {
    const eligibilities: string[] = [];
    if (
        status &
        (HouseholdEligibilityStatus.Medicare | HouseholdEligibilityStatus.MedicareHousehold)
    ) {
        eligibilities.push('Medicare');
    }
    if (status & HouseholdEligibilityStatus.Medicaid) {
        eligibilities.push('Medicaid');
    }
    if (status & HouseholdEligibilityStatus.CHIP) {
        eligibilities.push('CHIP');
    }
    if (eligibilities.length === 0) {
        eligibilities.push('other coverage');
    }
    return eligibilities.joinSerialComma();
};

export const getEligibilityData = (eligibilityStatus: HouseholdEligibilityStatus | undefined) =>
    flagsToValuesArray(eligibilityStatus, HouseholdEligibilityStatus);

export const getEligibilityInfo = (householdEligibility: IMemberEligibility[], id: string) => {
    const eligibilityStatus = householdEligibility.find((x) => x.memberId === id)
        ?.eligibilityStatus;
    const otherEligibilityStatus = householdEligibility.find((x) => x.memberId === id)
        ?.otherCoverageEligibility;
    return { eligibilityStatus, otherEligibilityStatus };
};

export const hasAnyDisqualifyingEligibilities = (
    status: HouseholdEligibilityStatus | undefined,
    otherEligibility: OtherCoverageEligibilities | undefined,
    onIchraPathway?: boolean
) => {
    let hasDisqualifyingStatus = !!(
        (status as HouseholdEligibilityStatus) &
        (HouseholdEligibilityStatus.Medicaid |
            HouseholdEligibilityStatus.Medicare |
            HouseholdEligibilityStatus.CHIP)
    );
    if (
        !onIchraPathway &&
        ![OtherCoverageEligibilities.SpouseEmployer, OtherCoverageEligibilities.None].contains(
            otherEligibility
        )
    ) {
        hasDisqualifyingStatus = !!(
            (status as HouseholdEligibilityStatus) & HouseholdEligibilityStatus.Other
        );
    }
    return hasDisqualifyingStatus;
};

export const doesMemberHaveAnyDisqualifyingEligibilities = (
    memberId: string,
    householdEligibility: IMemberEligibility[],
    isInIchraPathway: boolean | undefined
) => {
    const { eligibilityStatus, otherEligibilityStatus } = getEligibilityInfo(
        householdEligibility,
        memberId
    );
    return hasAnyDisqualifyingEligibilities(
        eligibilityStatus,
        otherEligibilityStatus,
        isInIchraPathway
    );
};
